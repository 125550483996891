// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
(function($) {
    'use strict';

    if (!Foundation.stylesheet) {
        Foundation._style_element = $('<style></style>').appendTo('head')[0];
        Foundation.stylesheet = Foundation._style_element.styleSheet;

        if (Foundation.stylesheet) {
            Foundation.stylesheet.cssRules = {
                length: 0
            };

            Foundation.stylesheet.insertRule = function(rule, index) {
                var media;
                var mediaMatch;
                var mediaRegex;
                var namespace;
                var ruleMatch;
                var ruleRegex;
                mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                mediaMatch = mediaRegex.exec(rule);
                media = '';

                if (mediaMatch) {
                    media = '@media ' + mediaMatch[1] + ' ';
                    rule = mediaMatch[2];
                }

                ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                ruleMatch = ruleRegex.exec(rule);
                namespace = '' + media + ruleMatch[1];
                rule = ruleMatch[2];

                return this.addRule(namespace, rule);
            };
        } else if (window.console) {
            console.log('Could not fix Foundation CSS rules...');
        }
    }
})(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

// Safely initiate foundation now
$(document).foundation();

// the rest of your code

$(window).load(function() {
    'use strict';

    // accessible image sprites
    HCTest();
});

$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });

    // menú
   // var navigation = $('#nav-main').okayNav({swipe_enabled: false});
    //$('.nav-main a').append('<span class="js-border"></span>');

    // ScrollToFixed
    function mediaqueryresponse1(mql){
        if (mql.matches){ // if media query matches
          // $('.main-nav').scrollToFixed();
           $('.main-header').scrollToFixed();
        }
        else {
          // $('.main-nav').trigger('detach.ScrollToFixed');
           $('.main-header').trigger('detach.ScrollToFixed');
        }
       }
    var mql = window.matchMedia('screen and (min-width: 14.0625em)')
    mediaqueryresponse1(mql) // call listener function explicitly at run time
    mql.addListener(mediaqueryresponse1) // attach listener function to listen in on state changes
    // -----------
    // Mobile menu
    // -----------

    var handleMatchMedia = function(mediaQuery) {


        // ≤ 1023px
        if (mediaQuery.matches) {
        // crear el botó del menú
        // $('.main-nav').prepend('<button class="hamburger hamburger--3dx menu-button" aria-expanded="false" aria-label="Menú" id="nav"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="menu-button__label vh">Menú</span></button>')
        // ocultar el menú
        $(".menu-wrapper")
            .attr("hidden", "")
            .addClass("js-hidden");
        // clicar el botó
        $(".main-nav button").on("click", function() {
            // afegir classe al botó
            $(this).toggleClass("is-active");
            // afegir classe al body
            $("body").toggleClass("js-menu-open");
            // canviar atributs del botó i del menú
            if ($(this).attr("aria-expanded") == "true") {
            $(this).attr("aria-expanded", "false");
            $(".menu-wrapper")
                .attr("hidden", "")
                .addClass("js-hidden");
            } else {
            $(this).attr("aria-expanded", "true");
            $(".menu-wrapper")
                .removeAttr("hidden")
                .removeClass("js-hidden");
            }
        });
        } else {
        // ≥ 1024px
        // treure el botó
        // $('.main-nav button').remove();
        // mostrar el menú
        $(".menu-wrapper")
            .removeAttr("hidden")
            .removeClass("js-hidden");
        }
    },
    mq = window.matchMedia("only screen and (max-width: 720px)");
    handleMatchMedia(mq);
    mq.addListener(handleMatchMedia);

    // --------
    // Submenús
    // --------

    // http://heydonworks.com/practical_aria_examples/#submenus
    $(".main-nav ul div")
    .prev("a")
    .attr("aria-haspopup", "true")
    .append('<span aria-hidden="true"> &#x25be;</span>');

    var showSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "false");
    };

    var hideSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "true");
    };

    $(".with-dropdowns > li > a").on("focus", function(e) {
    hideSubmenu($('[aria-label="submenu"]'));
    });

    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }

    // slick slider
    $('.slider').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 780,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
    //altura destacades subportada
    $(window).resize(function(){
       if ($('#principal').length > 0) {
            var height = $("#principal").height();
            $('.secundaria').css('min-height',height);
       }
    })

    $(window).resize(); //on page load
    /*------------------*/
  /* Lazy load images */
  /*------------------*/
      $("img.lazy:not('img.noLazy')").show().lazyload({
        effect: "fadeIn"
    //      failure_limit: 2,
    //      threshold: 400
    //      skip_invisible: false
      });
    $(function() {
        $("img.lazySlider").lazyload({
            event : "sporty"
        });
    });

    $(window).bind("load", function() {
        var timeout = setTimeout(function() {
            $("img.lazySlider").trigger("sporty")
        }, 100);
    });

      $("img.lazyNoticies").lazyload({
        effect: "fadeIn",
        threshold: 300,
        failure_limit: 5//
      });
      /*----------------------*/
      /* END Lazy load images */
      /*----------------------*/
    $('a.dropdown__toggle').on('click', function(){
        if ( $( this ).hasClass( "is-active" ) ) {
            $(this).removeClass('is-active');
            $(this).parent().find('.dropdown').removeClass('is-active').addClass('is-off');
        }else{
            $('a.dropdown__toggle').removeClass('is-active');
            $('.dropdown').removeClass('is-active').addClass('is-off');
            $(this).addClass('is-active');
            $(this).parent().find('.dropdown').removeClass('is-off').addClass('is-active');
        }
        return false;
    });
    $('a.search').on('click', function(){
        if ( $( this ).hasClass( "is-active" ) ) {
            $(this).removeClass('is-active');
            $(this).parent().find('.dropdown').removeClass('is-active').addClass('is-off');
        }else{
            $('a.dropdown__toggle').removeClass('is-active');
            $('.dropdown').removeClass('is-active').addClass('is-off');
            $(this).addClass('is-active');
            $(this).parent().find('.dropdown').removeClass('is-off').addClass('is-active');
            $('#search__box input').focus();
        }
        return false;
    });
    $(document).mouseup(function (e)
    {
        var container = $(".tools__item");

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            $('a.dropdown__toggle, a.search').removeClass('is-active');
            $('.dropdown').removeClass('is-active').addClass('is-off');
        }
    });

    $( ".especial .opacity a" ).hover(
      function() {
        $( ".especial .opacity" ).addClass( "hover" );
      }, function() {
        $( ".especial .opacity" ).removeClass( "hover" );
      }
    );
    $('.cerca-dades .search-form__clear-input').on('click', function(){
        $('.cerca-dades input').val('');
        $('.cerca-dades input').focus();
        return false;
    });

});
